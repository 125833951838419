import React, { FC, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Input, Button, Select } from "antd";
import PopupBackground from "@component/PopupBackground";
import { useParams } from "react-router-dom";
import ruleApi, { CreateCouponParams, CouponMethodValue } from "@api/ruleApi";
import { FormInstance } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import type { LocalFormType } from "..";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 250px;
  padding: 32px 28px 20px 28px;
  background-color: white;
`;
const InputGrid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  column-gap: 38px;
  row-gap: 15px;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledInput = styled(Input)<{ error: boolean }>`
  width: 395px;
  ${({ error }) =>
    error &&
    `
    border: 1px solid red;
  `}
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Annotation = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const ErrorText = styled.div`
  margin-top: 18px;
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
`;
const StyledSelect = styled(Select)`
  width: 70px;
`;

interface Props {
  form: FormInstance<LocalFormType>;
  onClose: () => void;
}

const validateCode = (value: string) => {
  if (value.length > 10) return false;
  return /^[a-zA-Z0-9]*$/.test(value);
};

const randomDigitsOptions = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const RandomCouponSetup: FC<Props> = (props) => {
  const { onClose, form } = props;
  const { id: ruleId } = useParams();

  const [prefix, setPrefix] = useState<string>();
  const [randomDigitsLength, setRandomDigitsLength] = useState<number>(7);
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validValue = validateCode(value);

    setPrefix(value);
    setIsCodeValid(validValue);
  };

  const handleOnSubmit = async () => {
    if (isCodeValid && prefix) {
      const params: CreateCouponParams = {
        ruleId: Number(ruleId!),
        couponMethod: CouponMethodValue.RANDOM,
        codePrefix: prefix,
        codeLength: randomDigitsLength,
      };
      // 打api不走redux: 為了要只更新兌換碼部分資訊而不是重拉整個ruleDetail (會蓋掉還沒儲存而被更動的欄位)
      try {
        const response = await ruleApi.createCoupons(params, true);
        form.setFieldsValue({
          couponInfo: response,
        });
        onClose();
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleSelectRandomDigitsLength = (value: SelectValue) => {
    setRandomDigitsLength(value as number);
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <InputGrid>
          <Title>前綴詞</Title>
          <StyledInput value={prefix} onChange={handleOnChange} error={!isCodeValid} />
          <Title>亂數個數</Title>
          <StyledSelect
            value={randomDigitsLength}
            onChange={(value) => handleSelectRandomDigitsLength(value as SelectValue)}
          >
            {randomDigitsOptions.map((opt) => (
              <Select.Option value={opt}>{opt}</Select.Option>
            ))}
          </StyledSelect>
        </InputGrid>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <Footer>
          <Annotation>註: 僅限輸入大小寫英文、數字，且前綴詞需在0~10字以內</Annotation>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" onClick={handleOnSubmit}>
              確定
            </Button>
          </ButtonContainer>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default RandomCouponSetup;
