import { InventorySheetType } from "@api/sharing/inventorySheetApi";
import { warehouseCodeOptions } from "@constant/WarehouseCodeValue";

import {
  fetchInventorySheetList,
  inventorySheetState,
  resetListParams,
  updateListParams,
} from "@redux/inventorySheetSlice";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";

import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

export const inventorySheetTypeOptions = [
  { label: "盤盈盤虧", value: InventorySheetType.INVENTORY },
  { label: "報廢", value: InventorySheetType.DISPOSE },
];

const FilterWrapper = styled.div<{ display: boolean }>`
  padding: "43px 13px";
  display: ${({ display }) => (display ? "block" : "none")};
`;

const { RangePicker } = DatePicker;

type Props = {
  open: boolean;
};

export default function InventorySheetFilter(props: Props) {
  const { open } = props;
  const dispatch = useDispatch();
  const { inventorySheetListParams } = useSelector(inventorySheetState);

  const [form] = Form.useForm();

  const dateFormatter = (date: moment.Moment | null) => date?.format("YYYY-MM-DD");
  const clearFilter = () => {
    dispatch(resetListParams());
    form.resetFields();
    dispatch(fetchInventorySheetList({ limit: inventorySheetListParams.limit, offset: 0 }));
  };

  const confirmFilter = (values: React.FormEvent<HTMLFormElement>) => {
    if (!Object.values(values).some((value) => !!value)) return;
    const { invTakingDate, ...formDate } = form.getFieldsValue();
    dispatch(updateListParams({ ...inventorySheetListParams, ...formDate }));
    dispatch(fetchInventorySheetList({ ...inventorySheetListParams, ...formDate }));
  };

  return (
    <FilterWrapper display={open}>
      <Form onFinish={confirmFilter} form={form} labelCol={{ span: 6 }} labelAlign="left">
        <Row gutter={[12, 4]}>
          <Col span={8}>
            <Form.Item label="SKU(品號)" name="sku">
              <Input allowClear />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="盤點日期" name="invTakingDate">
              <Form.Item name="invTakingDateAfter" noStyle />
              <Form.Item name="invTakingDateBefore" noStyle />
              <RangePicker
                onChange={(date) => {
                  if (!date) return;
                  form.setFieldValue("invTakingDateAfter", dateFormatter(date[0]));
                  form.setFieldValue("invTakingDateBefore", dateFormatter(date[1]));
                }}
                placeholder={["開始日期", "結束日期"]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="倉庫" name="warehouseCode">
              <Select options={warehouseCodeOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]}>
          <Col span={8}>
            <Form.Item label="類型" name="purposeType">
              <Select options={inventorySheetTypeOptions} placeholder="請選擇" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 4]} justify="end">
          <Col>
            <Button onClick={clearFilter}>清除篩選條件</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Col>
        </Row>
      </Form>
    </FilterWrapper>
  );
}
