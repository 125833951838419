export enum WarehouseCode {
  BLACK_CAT = "TCAT",
  REN_TRAP = "RENTRAP",
  STORE = "STORE",
}

export const warehouseCodeOptions = [
  { label: "黑貓", value: WarehouseCode.BLACK_CAT },
  { label: "藍田", value: WarehouseCode.REN_TRAP },
  { label: "Urmart 門市倉", value: WarehouseCode.STORE },
];

export const warehouseCodeOptionsUrmartDisabled = [
  { label: "黑貓", value: WarehouseCode.BLACK_CAT },
  { label: "藍田", value: WarehouseCode.REN_TRAP },
  { label: "Urmart 門市倉", value: WarehouseCode.STORE, disabled: true },
];

export const getWarehouseCode = (warehouseCode: string) => {
  return warehouseCodeOptions.find((option) => option.value === warehouseCode) || null;
};
