export enum ApprovalStatus {
  PENDING = 1, // 待審核
  APPROVED = 2, // 審核通過
  REJECT = 3, // 審核不通過
  NOT_REQUIRED = 4, // 不需審核
}
export const approvalStatusOptions = [
  { label: "待審核", value: ApprovalStatus.PENDING },
  { label: "審核通過", value: ApprovalStatus.APPROVED },
  { label: "審核不通過", value: ApprovalStatus.REJECT },
  { label: "不需審核", value: ApprovalStatus.NOT_REQUIRED },
];

export enum OrderStatus {
  CANCEL = 0,
  WAITING_APPLY = 1,
  WAITING_APPROVE = 2,
  WAITING_DEPLOY = 10,
  PROCESSING = 20,
  PICKING = 30,
  OUT_OF_STOCK = 40,
  ALREADY_SHIPPED = 50,
  SHIPPING_ABNORMAL = 60,
  FINISH = 70,
}
export const orderStatusOptions = [
  { label: "取消", value: OrderStatus.CANCEL },
  { label: "待申請", value: OrderStatus.WAITING_APPLY },
  { label: "待審核", value: OrderStatus.WAITING_APPROVE },
  { label: "待拋單", value: OrderStatus.WAITING_DEPLOY },
  { label: "已拋單/待處理", value: OrderStatus.PROCESSING },
  { label: "撿貨中", value: OrderStatus.PICKING },
  { label: "庫存不足", value: OrderStatus.OUT_OF_STOCK },
  { label: "配送中", value: OrderStatus.ALREADY_SHIPPED },
  { label: "配送異常", value: OrderStatus.SHIPPING_ABNORMAL },
  { label: "已送達", value: OrderStatus.FINISH },
];

export enum SpecialShipmentPurpose {
  ORDER = 1,
  WITHDRAW = 2,
  MARKETING = 3,
  INSPECTION = 4,
}
export const specialShipmentPurposeOptions = [
  { label: "出貨", value: SpecialShipmentPurpose.ORDER },
  { label: "退倉", value: SpecialShipmentPurpose.WITHDRAW },
  { label: "行銷推廣", value: SpecialShipmentPurpose.MARKETING },
  { label: "檢驗", value: SpecialShipmentPurpose.INSPECTION },
];
