import React, { useEffect, useState } from "react";
import { ProcessingLineType } from "@api/tryall/ProcessingApplicationApi";
import PageTitle from "@component/PageTitle";
import {
  fetchProcessingAppDetail,
  ProcessingApplicationState,
  uploadToWMSSystem,
} from "@redux/ProcessingApplicationSlice";
import { Button, message, Modal, Row, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TabWrapper } from "src/styles/common";
import ProcessingApplicationContent from "./ProcessingApplicationContent";
import ProcessingApplicationInfo from "./ProcessingApplicationInfo";
import { ProcessingStatus } from "./ProcessingStatus";

const ProcessingApplicationTab = () => {
  const dispatch = useDispatch();
  const { id: applicationId } = useParams();
  const { details } = useSelector(ProcessingApplicationState);
  const [editModal, setEditModal] = useState(false);
  const [activeKey, setActiveKey] = useState("ProcessingAppInfo");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { processingLineList } = useSelector(ProcessingApplicationState);
  const {
    [ProcessingLineType.TARGET]: processingLineTargetList,
    [ProcessingLineType.SOURCE]: processingLineSourceList,
  } = processingLineList;

  const handleUploadToWMS = () => {
    if (showAlertModal) {
      Modal.warning({
        title: "目標品項與來源品項之品號一樣但數量不一樣，請修正",
      });
      return;
    }
    if (processingLineTargetList?.length && processingLineSourceList?.length) {
      dispatch(uploadToWMSSystem(Number(applicationId)));
      return;
    }
    message.error("請確認加工品項與加工品項來源是否都有資料");
  };

  const operations = (
    <Row justify="end">
      {activeKey === "ProcessingAppInfo" ? (
        <Button
          type="primary"
          onClick={() => setEditModal(!editModal)}
          disabled={details?.status !== ProcessingStatus.PENDING}
        >
          編輯
        </Button>
      ) : (
        <Button onClick={handleUploadToWMS} disabled={details?.status !== ProcessingStatus.PENDING}>
          上傳至WMS
        </Button>
      )}
    </Row>
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(fetchProcessingAppDetail(Number(applicationId)));
    }
  }, [applicationId, dispatch]);

  return (
    <TabWrapper>
      <PageTitle title="加工單內容" />
      <Tabs
        type="card"
        tabBarExtraContent={operations}
        defaultActiveKey="ProcessingAppInfo"
        onChange={(nowActiveKey) => {
          setActiveKey(nowActiveKey);
        }}
      >
        <Tabs.TabPane tab="加工單" key="ProcessingAppInfo">
          {details && (
            <ProcessingApplicationInfo
              details={details}
              showEditModal={editModal}
              onModalClose={() => {
                setEditModal(false);
              }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="加工單內容" key="ProcessingAppContent">
          <ProcessingApplicationContent setShowAlertModal={setShowAlertModal} />
        </Tabs.TabPane>
      </Tabs>
    </TabWrapper>
  );
};
export default ProcessingApplicationTab;
