import React, { FC, useEffect, useMemo } from "react";
import { Col, Row, Space, Typography } from "antd";
import styled from "styled-components";
import { fetchProductStockList, updateProductStockListParam } from "@redux/productEditSlice";
import { FetchProductStockListParam, ProductStockListItem } from "@api/productApi";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { useParams } from "react-router-dom";
import moment from "moment";
import IntegratedTable from "@component/Table/IntegratedTable";
import { TabContentWrapper, TableWrapper } from "src/styles/common";

const { Title, Paragraph } = Typography;

const TableNoWrap = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const tableColumns = [
  {
    title: <TableNoWrap>有效日期</TableNoWrap>,
    key: "effectiveDate",
    dataIndex: "effectiveDate",
    width: 110,
  },
  {
    title: <TableNoWrap>良/壞品</TableNoWrap>,
    key: "qualified",
    dataIndex: "qualified",
    render: (value: ProductStockListItem["qualified"]) => (value ? "良品" : "壞品"),
    width: 110,
  },
  {
    title: <TableNoWrap>庫存數</TableNoWrap>,
    key: "stockQty",
    dataIndex: "stockQty",
    width: 110,
  },
];

const ProductStock: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();

  const { productStockListResult, productStockListParam, isFetchingProductStockList, productDetail } = useSelector(
    (state: RootState) => state.productEdit,
  );
  const { count, results: productStockList } = productStockListResult;

  // 商品固定在每天9:15更新, 若當前時間小於今天的 09:15,則返回前一天的 09:15, 否則返回今天的 09:15
  const getPredictRenewTime = useMemo(() => {
    const now = moment();
    const today915 = moment().set({ hour: 9, minute: 15, second: 0, millisecond: 0 });

    if (now.isBefore(today915)) {
      return today915.subtract(1, "days").format("YYYY-MM-DD HH:mm");
    }
    return today915.format("YYYY-MM-DD HH:mm");
  }, []);

  useEffect(() => {
    dispatch(fetchProductStockList(Number(productId)));
  }, [dispatch, productId, productStockListParam]);

  return (
    <TabContentWrapper>
      <Title level={3}>藍田倉庫</Title>
      <Paragraph type="danger">更新頻率：每十分鐘更新一次</Paragraph>

      <Paragraph>庫存數：{productDetail.tryallStockQty}</Paragraph>
      <Title level={3}>黑貓倉庫</Title>
      <Paragraph>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Row>
            <Col span={4}>庫存數 </Col>
            <Col>{productDetail.stockQty}</Col>
          </Row>
          <Row>
            <Col span={4}>預扣量 </Col>
            <Col> {productDetail.holdStockQty}</Col>
          </Row>
          <Row>
            <Col span={4}>可賣量 </Col>
            <Col>{productDetail.canSalesQty}</Col>
          </Row>
          <Row>
            <Col span={4}>保存量</Col> <Col>{productDetail.retentionStockQty}</Col>
          </Row>
        </Space>
      </Paragraph>
      <Paragraph type="danger">各效期更新頻率：每日更新一次，最終更新時間： {getPredictRenewTime}</Paragraph>
      <TableWrapper>
        <IntegratedTable
          loading={isFetchingProductStockList}
          isPagination
          paginationConfig={{
            totalCount: count,
            updateFilterParams: (filter: FetchProductStockListParam) => dispatch(updateProductStockListParam(filter)),
            filterParams: productStockListParam,
          }}
          dataSource={productStockList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
        />
      </TableWrapper>
    </TabContentWrapper>
  );
};

export default ProductStock;
