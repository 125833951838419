import {
  convertCurrencyType,
  convertRegionType,
  Currency,
  Incoterms,
  PickupType,
  RegionType,
} from "@api/throughShipment/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import {
  externalOrderState,
  fetchAccessPointList,
  fetchCombineExternalOrderData,
  fetchContactInfoList,
  fetchCreateExternalOrder,
  fetchDealerList,
  fetchExternalOrderInfo,
  fetchRetailList,
  fetchRetailRegion,
  fetchUpdateExternalOrder,
  updateNowCurrency,
} from "@redux/externalOrderSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, DatePicker, Form, Input, Radio, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import UploadFIleButton from "./UploadFileButton";

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid #f0f0f0;
`;
const Row = styled.div<{ marginTop?: string }>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop};
`;
const RowTitle = styled.div<{ bold?: boolean }>`
  align-self: flex-start;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RightSide = styled.div`
  margin-left: 15px;
`;
const ErrorMessage = styled.div`
  height: 22px;
  color: ${({ theme }) => theme.colorSecondary500};
`;
const CustomFormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-explain-error {
    min-height: 0;
  }
`;

interface FormState {
  currency: Currency;
  currencyRate: string;
  estimatedShippingDate: Moment;
  specifiedDeliveryDate: Moment;
  incoterms: Incoterms;
  pickupType: PickupType;
  retailerContactEmail: string;
  retailerContactName: string;
  retailerContactPhone: string;
  retailerName: number;
  retailerProductlineName: number[];
  retailerRegion: number;
  retailerRegionType: RegionType;
  retailerWarehouse: number;
  matoFilePath?: String;
}

type Props = {
  onNextStep: () => void;
};

const EditExternalOrder = (props: Props) => {
  const { onNextStep } = props;

  const dispatch = useDispatch();
  const {
    retailRegion,
    retailList,
    dealerList,
    contactInfoList,
    accessPointList,
    createExternalOrderId,
    externalOrderInfo,
    combineFetchExternalOrderData,
    isEditExternalOrderSuccess,
    isFetchingStep1Done,
  } = useSelector(externalOrderState);

  const navigate = useNavigate();
  const { externalOrderId } = useParams();

  const [form] = Form.useForm<FormState>();

  const retailerRegionTypeForm = Form.useWatch("retailerRegionType", form);

  const generateWarehouseError = (selectProudctLine: number[]) => {
    if (selectProudctLine?.length === 1 && accessPointList.results.length === 0) {
      return "無資料，請至[通路/經銷商]建立出貨倉點";
    }
    if (selectProudctLine?.length > 1 && accessPointList.results.length === 0) {
      return "無共同倉點，請重新選擇通路線別";
    }
    return "";
  };
  const handleUploadFile = (filePath?: String) => {
    const matoFilePath = filePath || "";
    form.setFieldsValue({ matoFilePath });
  };

  const onSubmit = (values: FormState) => {
    const retailerProductlineName = dealerList.results
      .filter((item) => values.retailerProductlineName.includes(item.productLine.id))
      .map((item) => item.productLine.name);
    const retailerName = retailList.results.find((item) => item.id === values.retailerName);
    const retailerRegion = retailRegion.results.find((item) => item.id === values.retailerRegion);

    const retailerWarehouse = accessPointList.results.find((item) => item.id === values.retailerWarehouse);

    const payload = {
      ...values,
      retailerWarehouse: undefined,
      currencyRate: Number(values.currencyRate),
      estimatedShippingDate: moment(values.estimatedShippingDate).format("YYYY-MM-DD"),
      retailerName: retailerName?.name || "",
      retailerProductlineName,
      retailerRegion: retailerRegion?.name || "",
      retailerRegionType: convertRegionType(values.retailerRegionType),
      retailerWarehouseName: retailerWarehouse?.name || "",
      retailerWarehouseAddress: retailerWarehouse?.address || "",
      retailerWarehouseCode: retailerWarehouse?.code || "",
      specifiedDeliveryDate: moment(values.specifiedDeliveryDate).format("YYYY-MM-DD"),
      matoDirPrefix: "external_order/mato/",
    };
    dispatch(updateNowCurrency(values.currency));
    if (externalOrderId) {
      dispatch(fetchUpdateExternalOrder({ ...payload, externalOrderId: parseInt(externalOrderId, 10) }));
    } else {
      dispatch(fetchCreateExternalOrder(payload));
    }
  };

  useEffect(() => {
    if (dealerList.results.length > 0) {
      form.setFieldsValue({ retailerContactPhone: dealerList.results[0].retailer.phone });
    }
  }, [form, dealerList]);

  useEffect(() => {
    if (
      contactInfoList?.results.length > 0 &&
      form.getFieldValue("retailerProductlineName") &&
      form.getFieldValue("retailerProductlineName").length > 0
    ) {
      const receiverContact = contactInfoList.results.filter((item) => item.name.includes("收貨單位"));
      const majorContact = contactInfoList.results.filter((item) => item.isMajor);

      form.setFieldsValue({
        retailerContactName:
          receiverContact.length > 0
            ? receiverContact.map((item) => item.name).toString()
            : majorContact.map((item) => item.name).toString(),
        retailerContactEmail:
          receiverContact.length > 0
            ? receiverContact.map((item) => item.email).toString()
            : majorContact.map((item) => item.email).toString(),
      });
    }
  }, [form, contactInfoList]);

  useEffect(() => {
    if (externalOrderInfo) {
      form.setFieldsValue({
        currency: externalOrderInfo.currency,
        currencyRate: String(externalOrderInfo.currencyRate),
        estimatedShippingDate: moment(externalOrderInfo.estimatedShippingDate),
        specifiedDeliveryDate: moment(externalOrderInfo.specifiedDeliveryDate),
        incoterms: externalOrderInfo.incoterms,
        pickupType: externalOrderInfo.pickupType,
        retailerContactEmail: externalOrderInfo.retailerContactEmail,
        retailerContactName: externalOrderInfo.retailerContactName,
        retailerContactPhone: externalOrderInfo.retailerContactPhone,
        retailerProductlineName: externalOrderInfo.retailerProductlineName,
      });
      dispatch(fetchCombineExternalOrderData(externalOrderInfo));
    }
  }, [form, externalOrderInfo, dispatch]);

  useEffect(() => {
    if (combineFetchExternalOrderData.retailerRegionType) {
      form.setFieldsValue(combineFetchExternalOrderData);
    }
  }, [form, combineFetchExternalOrderData]);

  useEffect(() => {
    if (createExternalOrderId) {
      navigate(`/operation-of-brand/external-order/${createExternalOrderId}`);
    }
  }, [createExternalOrderId, navigate]);

  useEffect(() => {
    if (externalOrderId) {
      dispatch(fetchExternalOrderInfo(parseInt(externalOrderId, 10)));
    }
  }, [dispatch, externalOrderId]);

  useEffect(() => {
    if (isEditExternalOrderSuccess) {
      onNextStep();
    }
  }, [isEditExternalOrderSuccess, onNextStep]);

  useEffect(() => {
    if (retailerRegionTypeForm !== RegionType.FOREIGN) {
      form.setFieldsValue({ currency: Currency.TWD, currencyRate: "1" });
    }
  }, [form, retailerRegionTypeForm]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 出貨訂單管理" />
      <Form
        form={form}
        initialValues={{ matoFilePath: "" }}
        onFinish={onSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Row>
          <RowTitle>
            預期拋單時間<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="estimatedShippingDate" rules={[{ required: true, message: "" }]}>
              <DatePicker
                locale={locale}
                style={{ width: "200px" }}
                disabledDate={(current) => moment().add(-1, "days") >= current || moment().add(1, "month") <= current}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            指定送達日<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="specifiedDeliveryDate" rules={[{ required: true, message: "" }]}>
              <DatePicker
                locale={locale}
                style={{ width: "200px" }}
                disabledDate={(current) => moment().add(-1, "days") >= current || moment().add(1, "month") <= current}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            合作通路類型<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <Form.Item name="retailerRegionType" rules={[{ required: true, message: "" }]}>
              <Select
                defaultValue={-1}
                onChange={(value) => {
                  dispatch(fetchRetailRegion({ regionType: value }));
                  form.resetFields([
                    "retailerRegion",
                    "retailerName",
                    "retailerProductlineName",
                    "retailerContactPhone",
                    "retailerContactName",
                    "retailerContactEmail",
                  ]);
                }}
              >
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                <Select.Option value={RegionType.DOMESTIC_CHANNEL}>台灣本地通路</Select.Option>
                <Select.Option value={RegionType.DOMESTIC_DISTRIBUTOR}>台灣本地經銷</Select.Option>
                <Select.Option value={RegionType.FOREIGN}>境外通路</Select.Option>
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            地區<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <Form.Item name="retailerRegion" rules={[{ required: true, message: "" }]}>
              <Select
                defaultValue={-1}
                onChange={(value, data: any) => {
                  dispatch(fetchRetailList({ region: value }));
                  form.resetFields([
                    "retailerName",
                    "retailerProductlineName",
                    "retailerContactPhone",
                    "retailerContactName",
                    "retailerContactEmail",
                  ]);
                }}
              >
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                {retailRegion.results.map((region) => (
                  <Select.Option value={region.id} key={region.name}>
                    {region.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路名稱<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <Form.Item name="retailerName" rules={[{ required: true, message: "" }]}>
              <Select
                defaultValue={-1}
                onChange={(value) => {
                  dispatch(fetchDealerList({ retailer: value, limit: 100, offset: 0 }));
                  form.resetFields([
                    "retailerProductlineName",
                    "retailerContactPhone",
                    "retailerContactName",
                    "retailerContactEmail",
                  ]);
                }}
              >
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                {retailList.results.map((retail) => (
                  <Select.Option value={retail.id} key={retail.name}>
                    {retail.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路線別<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={350}>
            <Form.Item name="retailerProductlineName" rules={[{ required: true, message: "" }]}>
              <Select
                mode="multiple"
                showArrow
                onChange={(value) => {
                  dispatch(
                    fetchContactInfoList({
                      retailer: dealerList.results[0].retailer.id,
                      productlines: value.toString(),
                      limit: 100,
                      offset: 0,
                    }),
                  );
                  dispatch(
                    fetchAccessPointList({
                      retailer: dealerList.results[0].retailer.id,
                      productlines: value.toString(),
                      limit: 100,
                      offset: 0,
                    }),
                  );
                  form.resetFields(["retailerContactName", "retailerContactEmail", "retailerWarehouse"]);
                }}
              >
                {dealerList.results.map((dealer) => (
                  <Select.Option value={dealer.productLine.id} key={dealer.productLine.name}>
                    {dealer.productLine.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>通路電話</RowTitle>
          <RowContent width={500}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item name="retailerContactPhone">
                  {getFieldValue("retailerContactPhone") ? (
                    <div>{getFieldValue("retailerContactPhone")}</div>
                  ) : (
                    <div>N/A(系統自動帶入)</div>
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>通路窗口</RowTitle>
          <RowContent width={500}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item name="retailerContactName">
                  {getFieldValue("retailerContactName") ? (
                    <div>{getFieldValue("retailerContactName")}</div>
                  ) : (
                    <div>N/A(系統自動帶入)</div>
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>通路電子郵件</RowTitle>
          <RowContent width={500}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item name="retailerContactEmail">
                  {getFieldValue("retailerContactEmail") ? (
                    <div>{getFieldValue("retailerContactEmail")}</div>
                  ) : (
                    <div>N/A(系統自動帶入)</div>
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            通路進貨倉點<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <CustomFormItem name="retailerWarehouse" rules={[{ required: true, message: "" }]}>
              <Select defaultValue={-1}>
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                {accessPointList.results.map((item) => (
                  <Select.Option value={item.id} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </CustomFormItem>
            <Form.Item shouldUpdate noStyle style={{ marginBottom: 10 }}>
              {({ getFieldValue }) => (
                <ErrorMessage>{generateWarehouseError(getFieldValue("retailerProductlineName"))}</ErrorMessage>
              )}
            </Form.Item>
          </RowContent>
          <RightSide>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="pickupType" rules={[{ required: true, message: "" }]}>
                  <Radio.Group disabled={!!generateWarehouseError(getFieldValue("retailerProductlineName"))}>
                    <Radio value={PickupType.SELF_PICKUP}>訂單自取</Radio>
                    <Radio value={PickupType.CHARTER}>訂單非自取 (專車)</Radio>
                    <Radio value={PickupType.HCT_CHARTER}>訂單非自取 (新竹)</Radio>
                    <Radio value={PickupType.T_CAT}>訂單非自取 (黑貓)</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            </Form.Item>
          </RightSide>
        </Row>
        <Row>
          <RowTitle>
            交易幣別<RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <Form.Item name="currency" rules={[{ required: true, message: "" }]}>
              <Select defaultValue={-1} disabled={retailerRegionTypeForm !== RegionType.FOREIGN}>
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                <Select.Option value={Currency.TWD}>{convertCurrencyType(Currency.TWD)}</Select.Option>
                <Select.Option value={Currency.USD}>{convertCurrencyType(Currency.USD)}</Select.Option>
                <Select.Option value={Currency.RMB}>{convertCurrencyType(Currency.RMB)}</Select.Option>
              </Select>
            </Form.Item>
          </RowContent>
          <RightSide>
            <Form.Item name="currencyRate" rules={[{ required: true, message: "" }]}>
              <Input placeholder="填寫當前匯率" disabled={retailerRegionTypeForm !== RegionType.FOREIGN} />
            </Form.Item>
          </RightSide>
        </Row>
        <Row>
          <RowTitle>貿易條件</RowTitle>
          <RowContent width={200}>
            <Form.Item name="incoterms">
              <Select defaultValue={-1}>
                <Select.Option value={-1} disabled>
                  請選擇
                </Select.Option>
                <Select.Option value={Incoterms.FOB}>{Incoterms.FOB}</Select.Option>
                <Select.Option value={Incoterms.CIF}>{Incoterms.CIF}</Select.Option>
                <Select.Option value={Incoterms.EXW}>{Incoterms.EXW}</Select.Option>
                <Select.Option value={Incoterms.DDU}>{Incoterms.DDU}</Select.Option>
              </Select>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>上傳麥頭</RowTitle>
          <RowContent width={500}>
            <Form.Item name="matoFilePath" hidden>
              <Input type="text" />
            </Form.Item>
            <UploadFIleButton onUpload={handleUploadFile} path={externalOrderInfo?.mato} />
          </RowContent>
        </Row>
        <Footer>
          <Button type="primary" htmlType="submit" disabled={externalOrderId ? !isFetchingStep1Done : false}>
            {externalOrderId ? "儲存此分頁" : "建立資料"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default EditExternalOrder;
