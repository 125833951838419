import { CreateProductDetailParam, ProductDetail, SyncMethodValue, UpdateProductDetailParam } from "@api/productApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import {
  createProduct,
  fetchBrandList,
  fetchProductDetail,
  loadMoreBrandList,
  updateProduct,
} from "@redux/productEditSlice";
import { RootState } from "@redux/rootReducer";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Form, Input, InputNumber, Row, Col, Select, Typography } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-left: 16px;
`;
const CustomRow = styled(Row)`
  margin-bottom: 24px;
`;
const RowTitle = styled(Col)<{ margin?: string }>`
  width: 150px;
  margin: ${({ margin }) => margin || "0 0 0 0"};
`;
const RowBoldTitle = styled(RowTitle)`
  font-weight: 700;
  margin-bottom: 12px;
`;
const RowGroup = styled.div`
  margin: 12px 0;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled(Col)<{ width?: number }>`
  width: ${({ width }) => width || 400}px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const Annotation = styled.span`
  margin-left: 11px;
  font-size: 14px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.25);
`;

interface Props {
  mode: "add" | "edit";
}

const syncMethodList = [
  {
    label: "同步",
    value: SyncMethodValue.SYNC,
  },
  {
    label: "不同步",
    value: SyncMethodValue.ASYNC,
  },
  {
    label: "轉單不同步",
    value: SyncMethodValue.ASYNC_TRANSFER_ORDER,
  },
];

const BasicInfo: FC<Props> = (props) => {
  const { mode } = props;
  const [form] = Form.useForm<ProductDetail>();
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { productDetail, brandListResult } = useSelector((state: RootState) => state.productEdit);
  const staffList = useSelector((state: RootState) => state.staffs.bdStaffData);

  useEffect(() => {
    dispatch(fetchBrandList(""));
  }, [dispatch]);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const staffOptions = useMemo(() => {
    const options = staffList.map((staff) => (
      <Select.Option key={staff.id} value={staff.id}>
        {staff.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    return options;
  }, [staffList]);

  useEffect(() => {
    if (mode === "edit") {
      dispatch(fetchProductDetail(Number(productId)));
    }
  }, [productId, dispatch, mode]);

  useEffect(() => {
    // 新增產品完拿到新的id 要導去edit頁
    if (mode === "add" && productDetail.id && productDetail.id > 0) {
      navigate(`/product/edit/${productDetail.id}`);
    }

    form.setFieldsValue(productDetail);
  }, [productDetail, mode, navigate, form]);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    switch (mode) {
      case "add": {
        const createParams: CreateProductDetailParam = {
          owner: formValues.owner.id,
          name: formValues.name,
          sku: formValues.sku,
          brand: formValues.brand.id,
          shelfLife: formValues.shelfLife,
          syncOption: formValues.syncStockOption,
          isSyncWarehouse: formValues.isSyncWarehouse,
          isActive: formValues.isActive,
          stockQty: formValues.stockQty,
          processingFee: formValues.processingFee,
          weightedCost: formValues.weightedCost,
          length: formValues.length,
          width: formValues.width,
          height: formValues.height,
          weight: formValues.weight,
          cartonLength: formValues.cartonLength,
          cartonWidth: formValues.cartonWidth,
          cartonHeight: formValues.cartonHeight,
        };

        dispatch(createProduct(createParams));
        break;
      }
      case "edit": {
        const params: UpdateProductDetailParam = {
          owner: formValues.owner.id,
          brand: formValues.brand.id,
          shelfLife: formValues.shelfLife,
          syncOption: formValues.syncStockOption,
          stockQty: formValues.syncStockOption === SyncMethodValue.SYNC ? undefined : formValues.stockQty,
          processingFee: formValues.processingFee,
          cartonLength: formValues.cartonLength,
          cartonWidth: formValues.cartonWidth,
          cartonHeight: formValues.cartonHeight,
        };
        const updateParams = {
          id: Number(productId),
          params,
        };
        dispatch(updateProduct(updateParams));
        break;
      }
      default:
    }
  };

  const handleOnSelect = (title: string) => (value: SelectValue, option: any) => {
    form.setFieldsValue({
      [title]: option,
    });
  };

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  useEffect(() => {
    if (productDetail?.brand) {
      dispatch(fetchBrandList(productDetail.brand.name));
    }
  }, [productDetail, dispatch]);

  return (
    <Wrapper>
      <Row style={{ marginBottom: "16px" }}>
        <Typography.Text type="danger">*基本資訊，無法編輯之欄位，需要至 Oracle 進行修正</Typography.Text>
      </Row>
      <Form
        form={form}
        initialValues={{ ...productDetail, processingFee: productDetail.processingFee ?? 10 }}
        // processingFee BE已有預設10,但還是再塞一次預設10塊
        onFinish={handleOnSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Row>
          <RowTitle margin="6px 0">ID</RowTitle>
          <Form.Item name="id" style={{ width: "86px" }}>
            <Input disabled />
          </Form.Item>
          <Annotation>*儲存後將自動帶入</Annotation>
        </Row>
        <CustomRow>
          <RowTitle>商品狀態</RowTitle>
          <RowContent width={188}>{productDetail.isActive ? "上架" : "下架"}</RowContent>
        </CustomRow>
        <Row>
          <RowTitle margin="6px 0">
            商開負責人
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Form.Item
            name={["owner", "id"]}
            rules={[
              () => ({
                validator(rule, value) {
                  if (value >= 0) return Promise.resolve();
                  return Promise.reject();
                },
              }),
            ]}
          >
            <StyledSelect
              showSearch
              onChange={(value, option) => handleOnSelect("owner")(value as SelectValue, option)}
              filterOption={(input, option) => {
                const { children } = option as any;
                return children.toLowerCase().includes(input.toLowerCase());
              }}
              style={{ width: "188px" }}
            >
              {staffOptions}
            </StyledSelect>
          </Form.Item>
        </Row>
        <CustomRow>
          <RowTitle>SKU(品號)</RowTitle>
          <RowContent width={188}>{productDetail.sku}</RowContent>
        </CustomRow>
        <CustomRow>
          <RowTitle>商品名稱</RowTitle>
          <RowContent>{productDetail.name}</RowContent>
        </CustomRow>
        <CustomRow>
          <RowTitle>商品成本</RowTitle>
          <RowContent>{productDetail.weightedCost}</RowContent>
        </CustomRow>
        <CustomRow>
          <RowTitle>藍田庫存</RowTitle>
          <RowContent>{productDetail.tryallStockQty}</RowContent>
        </CustomRow>
        <Row>
          <RowTitle margin="6px 0">黑貓庫存</RowTitle>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.syncStockOption !== currentValues.syncStockOption}
          >
            {({ getFieldValue }) => {
              const syncStockOption = getFieldValue("syncStockOption");
              const isDisabled = syncStockOption === SyncMethodValue.SYNC;
              return (
                <Form.Item name="stockQty">
                  <InputNumber disabled={isDisabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Row>
        <Row>
          <RowTitle margin="6px 0">
            品牌
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Form.Item
            name={["brand", "id"]}
            rules={[
              () => ({
                validator(rule, value) {
                  if (value >= 0) return Promise.resolve();
                  return Promise.reject();
                },
              }),
            ]}
          >
            <StyledSelect
              showSearch
              filterOption={false}
              onChange={(value, option) => handleOnSelect("brand")(value as SelectValue, option)}
              onSearch={handleOnBrandSearch}
              style={{ width: "188px" }}
            >
              {brandOptions}
            </StyledSelect>
          </Form.Item>
        </Row>
        <Row>
          <RowTitle margin="6px 0">
            總效期天數 <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Form.Item name="shelfLife" rules={[{ required: true, message: "" }]}>
            <InputNumber />
          </Form.Item>
        </Row>
        <Row>
          <RowTitle margin="6px 0">黑貓倉處理費</RowTitle>
          <Form.Item name="processingFee">
            <InputNumber />
          </Form.Item>
        </Row>
        <Row>
          <RowTitle margin="6px 0">黑貓倉資訊同步方式</RowTitle>
          <Form.Item name="syncStockOption">
            <StyledSelect options={syncMethodList} style={{ width: "188px" }} />
          </Form.Item>
        </Row>
        <CustomRow>
          <RowTitle>同步至黑貓倉庫</RowTitle>
          <RowContent>{productDetail.isSyncWarehouse ? "是" : "否"}</RowContent>
        </CustomRow>
        <RowGroup>
          <Row>
            <RowBoldTitle>單一品項</RowBoldTitle>
          </Row>
          <CustomRow>
            <RowTitle>重量(g)</RowTitle>
            <RowContent>{productDetail.weight}</RowContent>
          </CustomRow>
          <CustomRow>
            <RowTitle>長(cm)</RowTitle>
            <RowContent>{productDetail.length}</RowContent>
          </CustomRow>
          <CustomRow>
            <RowTitle>寬(cm)</RowTitle>
            <RowContent>{productDetail.width}</RowContent>
          </CustomRow>
          <CustomRow>
            <RowTitle> 高(cm)</RowTitle>
            <RowContent>{productDetail.height}</RowContent>
          </CustomRow>
        </RowGroup>
        <RowGroup>
          <Row>
            <RowBoldTitle>外箱尺寸</RowBoldTitle>
          </Row>
          <Row>
            <RowTitle>長(cm)</RowTitle>
            <Form.Item name="cartonLength">
              <InputNumber min={0} />
            </Form.Item>
          </Row>
          <Row>
            <RowTitle>寬(cm)</RowTitle>
            <Form.Item name="cartonWidth">
              <InputNumber min={0} />
            </Form.Item>
          </Row>
          <Row>
            <RowTitle>高(cm)</RowTitle>
            <Form.Item name="cartonHeight">
              <InputNumber min={0} />
            </Form.Item>
          </Row>
        </RowGroup>
        <Footer>
          <Button type="primary" htmlType="submit">
            {mode === "add" ? "建立資料" : "儲存此分頁"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default BasicInfo;
