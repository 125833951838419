import moment from "moment";
import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

interface ConfigSettingApi {
  fetchEnvironmentList: () => Promise<EnvironmentParams>;
  fetchUpdateEnvironmentList: (params: EnvironmentParams) => Promise<EnvironmentParams>;
  fetchPurgeLoginCache: () => Promise<void>;
}

export interface EnvironmentParams {
  defaultRecommendPageIds?: number[];
  vipPromotionStartDate?: string;
  vipPromotionContent?: string;
  defaultAdditionPurchaseApplyMaxQty?: string;
  promotionMaxNumberOfBindRules?: string;
  vipPromotionImage?: string;
  cvsLimitLongestEdge?: string;
  cvsLimitSecondLongestEdge?: string;
  cvsLimitShortestEdge?: string;
  cvsLimitVolume?: string;
  cvsLimitWeight?: string;
  cvsLimitPrice?: string;
  newCustomerPromotionRuleIds?: number[];
  vipCustomerRenewPromotionRuleIds?: number[];
  searchKeywords?: string[];
  vipPromotionEndDate?: string;
  deployCvsSuspendStartDate?: string;
  deployCvsSuspendEndDate?: string;
  serverFields?: [];
}
export interface FormatedEnvironmentResult {
  timePeriod: moment.Moment[];
  defaultRecommendPageIds: number[];
  vipPromotionStartDate: string;
  vipPromotionContent: string;
  defaultAdditionPurchaseApplyMaxQty: string;
  promotionMaxNumberOfBindRules: string;
  vipPromotionImage: string;
  cvsLimitLongestEdge: string;
  cvsLimitSecondLongestEdge: string;
  cvsLimitShortestEdge: string;
  cvsLimitVolume: string;
  cvsLimitWeight: string;
  cvsLimitPrice: string;
  newCustomerPromotionRuleIds: number[];
  searchKeywords: string[];
  vipPromotionEndDate: string;
  deployCvsSuspendStartDate: string;
  deployCvsSuspendEndDate: string;
}

const configSettingApi: ConfigSettingApi = {
  fetchEnvironmentList: async () => {
    const getParams = {};
    const response = await apiClient.getData("/manage/global-preferences/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateEnvironmentList: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.patchData("/manage/global-preferences/batch-patch/", requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchPurgeLoginCache: async () => {
    await apiClient.getData("/manage/throttle/purge/", {});
  },
};

export default configSettingApi;
