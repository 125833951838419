import React, { FC } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import BasicInfo from "./BasicInfo";
import CategoryTagType from "./CategoryTagType";
import Nutrition from "./Nutrition";
import Collab from "./Collab";
import SaleMethod from "./SaleMethod";
import ProductStock from "./ProductStock";

interface Props {
  mode: "add" | "edit";
}

const Wrapper = styled.div`
  padding: 12px 24px 71px 16px;
`;
const TabsContainer = styled.div`
  padding-top: 13px;
  padding-left: 22px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const Edit: FC<Props> = (props) => {
  const { mode } = props;
  const productDetail = useSelector((state: RootState) => state.productEdit.productDetail);
  const { owner, brand, shelfLife } = productDetail;

  const showOtherTabs = owner || brand || !!shelfLife;
  return (
    <Wrapper>
      <PageTitle title={`商品 - ${mode === "add" ? "新增" : productDetail.name}`} />
      <TabsContainer>
        <Tabs defaultActiveKey="basic" type="card" destroyInactiveTabPane>
          <Tabs.TabPane tab="基本資訊" key="basic">
            <BasicInfo mode={mode} />
          </Tabs.TabPane>
          {mode === "edit" && showOtherTabs ? (
            <>
              <Tabs.TabPane tab="品類屬性" key="tagType">
                <CategoryTagType />
              </Tabs.TabPane>
              <Tabs.TabPane tab="營養成分" key="nutrition">
                <Nutrition />
              </Tabs.TabPane>
              <Tabs.TabPane tab="合作方式" key="collab" disabled={!productDetail.id}>
                <Collab />
              </Tabs.TabPane>
              <Tabs.TabPane tab="銷售方式" key="saleMethod">
                <SaleMethod />
              </Tabs.TabPane>
              <Tabs.TabPane tab="庫存資訊" key="stock">
                <ProductStock />
              </Tabs.TabPane>
            </>
          ) : null}
        </Tabs>
      </TabsContainer>
    </Wrapper>
  );
};

export default Edit;
