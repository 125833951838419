import React, { useMemo } from "react";
import type { Table as TypeTable } from "antd";
import { Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import TablePageSizeController from "./TablePageSizeController";

type PropsT<T extends (...args: any) => any> = Parameters<T>[0];
type TableProps = PropsT<typeof TypeTable>;

type PaginationConfig = {
  updateFilterParams: (params?: any) => void;
  filterParams: { offset: number; limit: number; [key: string]: any };
  totalCount: number;
};

type PaginationProps =
  | {
      isPagination: false;
    }
  | {
      isPagination: true;
      paginationConfig: PaginationConfig;
    };

type Props = PaginationProps & TableProps & { columns: ColumnsType<any> | undefined };

/**
 * fetchList要由父層監聽paginationConfig.filterParams的變化後再fetch, 通常會和redux一起使用
 * @param isPagination 是否要顯示分頁
 * @param props  antd Table props with  paginationConfig
 * @returns
 */
export default function IntegratedTable(props: Props) {
  const { isPagination, ...tableProps } = props;

  // 上方每頁筆數
  const pageSizeInfo = useMemo(() => {
    if (isPagination) {
      const { paginationConfig } = props as { paginationConfig: PaginationConfig };
      const { updateFilterParams, filterParams } = paginationConfig;
      const onPageSizeChange = (value: SelectValue) => {
        const filter = {
          ...filterParams,
          limit: value as number,
        };
        updateFilterParams(filter);
      };
      return {
        totalCount: paginationConfig.totalCount,
        limit: filterParams.limit,
        onPageSizeChange,
      };
    }

    return undefined;
  }, [isPagination, props]);

  // 下方頁碼
  const pagination = useMemo(() => {
    if (isPagination) {
      const { paginationConfig } = props as { paginationConfig: PaginationConfig };
      const { totalCount, filterParams, updateFilterParams } = paginationConfig;
      const { offset, limit } = filterParams;

      const onPaginationChange = (page: number) => {
        const filter = {
          ...filterParams,
          offset: (page - 1) * limit,
        };
        updateFilterParams(filter);
      };

      return {
        pageSize: limit,
        current: Math.floor(offset / limit) + 1,
        showSizeChanger: false,
        total: totalCount,
        onChange: onPaginationChange,
      };
    }
    return undefined;
  }, [isPagination, props]);

  return (
    <>
      {pageSizeInfo && (
        <TablePageSizeController
          totalCount={pageSizeInfo.totalCount}
          onPageSizeChange={pageSizeInfo.onPageSizeChange}
        />
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Table {...tableProps} pagination={isPagination ? pagination : false} />
    </>
  );
}
