/* 
出貨單：
1. 列表(篩選） [GET]  /api/v1/manage/specialshipment/ 
2. 新增 [Post]  /api/v1/manage/specialshipment/ 
3. 編輯 [PUT]  /api/v1/manage/specialshipment/<pk>/
4. 刪除（作廢） [DELETE] /api/v1/manage/specialshipment/<pk>/
5. 取得運輸列表 [GET] /api/v1/manage/logistic/carrier-relation/
2出貨單詳情：
1. 詳情 [GET] /api/v1/manage/specialshipment/<pk>/
2. 審核/不通過 [PUT]送出申請/ 審核/不通過 [PUT] /api/v1/manage/specialshipment/<pk>/process/
3出貨商品：
1. 列表 [GET] /api/v1/manage/specialshipment/<specialshipment_pk>/lines/
2. 詳情 [GET]. /api/v1/manage/specialshipment/<specialshipment_pk>/lines/<pk>/
3. 新增 [Post]. /api/v1/manage/specialshipment/<specialshipment_pk>/lines/
4. 編輯 [PUT]  /api/v1/manage/specialshipment/<specialshipment_pk>/lines/<pk>/
5. 刪除 [DELETE]. /api/v1/manage/specialshipment/<specialshipment_pk>/lines/<pk>/
6. 批次新增 [POST] 先不做
7. 取得商品倉別列表[GET]. /v1/manage/product/product-warehouse/
8. 取得商品倉別庫存列表 [GET]. /v1/manage/product/product-warehouse/stocks/
  */

import CoreAPI from "@api/CoreAPI";
import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "@api/utils/transformCamelToSnake";
import { ApprovalStatus, OrderStatus, SpecialShipmentPurpose } from "@page/SpecialShipment/specialShipmentConstants";
import { ListResponseType } from "src/types";

export interface SpecialShipmentListParams {
  id?: number;
  orderNumber?: string;
  estDeployedDateAfter?: string;
  estDeployedDateBefore?: string;
  shippingDateAfter?: string;
  shippingDateBefore?: string;
  sku?: string;
  status?: number;
  approvalStatus?: number;
  purpose?: SpecialShipmentPurpose;
  ordering?: string;
  limit: number;
  offset: number;
}

export interface SpecialShipmentItem {
  id: number;
  purpose: SpecialShipmentPurpose;
  orderNumber: string;
  warehouseCode: string;
  warehouseName: string;
  receiverName: string;
  approvalStatus: ApprovalStatus;
  status: OrderStatus;
  shippingStatus: number;
  estDeployedDate: string;
  shippingAt: string;
  totalQty: number;
  totalCost: number;
  staffName: string;
  createdAt: string;
  destroyedAt: string;
}
export interface SpecialShipmentDetail {
  id: number;
  approvalStatus: ApprovalStatus;
  arrivedAt: string;
  canCancel: boolean;
  canEdit: boolean;
  canSubmit: boolean;
  carrierId: number;
  carrierRelationCode: number;
  carrierRelationName: string;
  ceoApproval: ApprovalStatus;
  condition: string;
  createdAt: string;
  desc: string;
  destroyedAt: string;
  destroyedBy: string;
  estArrivedDate: string;
  estDeployedDate: string;
  financeApproval: ApprovalStatus;
  managerApproval: ApprovalStatus;
  orderNumber: string;
  purpose: SpecialShipmentPurpose;
  receiverAddress: string;
  receiverName: string;
  receiverPhone: string;
  receiverZipcode: string;
  sectionManagerApproval: ApprovalStatus;
  shippingAt: string;
  shippingNumber: string;
  shippingStatus: number;
  staffName: string;
  status: OrderStatus;
  warehouseCode: string;
  warehouseName: string;
  deliveryType: string;
}

export interface UpdateShipmentDetail {
  purpose: number;
  condition?: string;
  desc: string;
  warehouseCode: string;
  carrierId: number;
  estDeployedDate: string;
  deliveryType: number;
  estArrivedDate: string;
  receiverName?: string;
  receiverPhone?: string;
  receiverAddress?: string;
  receiverZipcode?: string;
}

export type CarrierItem = {
  id: number;
  name: string;
  carrierId: number;
  carrierType: number;
  warehouse: number;
  warehouseCode: string;
  code: string;
};
export type CarrierParams = Partial<Omit<CarrierItem, "id" | "name">> & {
  purpose?: SpecialShipmentPurpose;
};

export enum ProcessType {
  APPLY = 1,
  APPROVE = 2,
  REJECT = 3,
}

export interface SSProductItem {
  id: number;
  specialShipment: number;
  warehouseStorageType: string;
  warehouseStorageName: string;
  sku: string;
  barcode: string;
  productName: string;
  effectiveDate: string;
  batch: string;
  qty: number;
  cost: number;
  stock: number;
}

export interface UpdateSSProductDetail {
  sku: string;
  storageType: string;
  effectiveDate: string;
  batch: string;
  qty: number;
}
export type ProductWarehouseInfo = {
  sku: string;
  warehouseCode: string;
  productName: string;
  barcode: string;
  weightedCost: number;
};

export type ProductWarehouseStockItem = {
  warehouseCode: string;
  storageType: string;
  effectiveDate: string;
  batch: string;
  canSalesQty: number;
};

const apiClient = new CoreAPI();

interface SpecialShipmentApi {
  // 出貨單：
  fetchSpecialShipmentList: (params: SpecialShipmentListParams) => Promise<ListResponseType<SpecialShipmentItem>>;
  createSpecialShipment: (payload: UpdateShipmentDetail) => Promise<number>;
  updateSpecialShipment: (shipmentId: number, payload: UpdateShipmentDetail) => Promise<void>;
  deleteSpecialShipment: (shipmentId: number) => Promise<void>;
  fetchCarrierList: (params: CarrierParams) => Promise<CarrierItem[]>;
  // 出貨單詳情：
  fetchSpecialShipmentDetail: (shipmentId: number) => Promise<SpecialShipmentDetail>;
  handleSpecialShipmentStatus: (shipmentId: number, processType: ProcessType) => Promise<void>;
  // 出貨商品：
  fetchSpecialShipmentProductList: (
    shipmentId: number,
    productParams: { limit: number; offset: number },
  ) => Promise<ListResponseType<SSProductItem>>;
  createSpecialShipmentProduct: (shipmentId: number, payload: UpdateSSProductDetail) => Promise<void>;
  updateSpecialShipmentProduct: (
    shipmentId: number,
    productId: number,
    payload: UpdateSSProductDetail,
  ) => Promise<void>;
  deleteSpecialShipmentProduct: (shipmentId: number, productId: number) => Promise<void>;
  fetchProductWarehouseInfo: (sku: string, warehouseCode: string) => Promise<ProductWarehouseInfo[]>; // 取得商品倉別列表：商品目前可以存在哪些出貨倉庫中
  fetchProductWarehouseStockList: (
    sku: string,
    warehouseCode: string,
  ) => Promise<{ stockInfo: ProductWarehouseStockItem[] }[]>; // 取得商品倉別庫存列表：倉庫中個小倉商品的庫存

  // 取得商品倉別庫存列表
}
const specialShipmentApi: SpecialShipmentApi = {
  fetchSpecialShipmentList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/specialshipment/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createSpecialShipment: async (params) => {
    const putParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/specialshipment/", putParams);
    return response.data.result.id;
  },
  updateSpecialShipment: async (shipmentId, params) => {
    const putParams = transformCamelToSnake(params);
    await apiClient.putData(`/manage/specialshipment/${shipmentId}/`, putParams);
  },
  deleteSpecialShipment: async (shipmentId) => {
    await apiClient.deleteData(`/manage/specialshipment/${shipmentId}/`, {});
  },
  fetchCarrierList: async (params) => {
    const putParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/logistic/carrier-relation/", putParams);
    return transformAPIKeyToCamel(response.data.result.results);
  },
  // 出貨單詳情：
  fetchSpecialShipmentDetail: async (shipmentId) => {
    const response = await apiClient.getData(`/manage/specialshipment/${shipmentId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  // 送出申請/審核/不通過
  handleSpecialShipmentStatus: async (shipmentId, processType) => {
    const getParams = transformCamelToSnake({ processType });
    await apiClient.putData(`/manage/specialshipment/${shipmentId}/process/`, getParams);
  },
  // 出貨商品：
  fetchSpecialShipmentProductList: async (shipmentId, productParams) => {
    const getParams = transformCamelToSnake(productParams);
    const response = await apiClient.getData(`/manage/specialshipment/${shipmentId}/lines/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createSpecialShipmentProduct: async (shipmentId, payload) => {
    const putParams = transformCamelToSnake(payload);
    await apiClient.postData(`/manage/specialshipment/${shipmentId}/lines/`, putParams);
  },
  updateSpecialShipmentProduct: async (shipmentId, productId, payload) => {
    const putParams = transformCamelToSnake(payload);
    await apiClient.putData(`/manage/specialshipment/${shipmentId}/lines/${productId}/`, putParams);
  },
  deleteSpecialShipmentProduct: async (shipmentId, productId) => {
    await apiClient.deleteData(`/manage/specialshipment/${shipmentId}/lines/${productId}/`, {});
  },
  fetchProductWarehouseInfo: async (sku, warehouseCode) => {
    const getParams = transformCamelToSnake({ sku, warehouseCode });
    const response = await apiClient.getData("/manage/product/product-warehouse/", getParams);

    return transformAPIKeyToCamel(response.data.result.results);
  },
  fetchProductWarehouseStockList: async (sku, warehouseCode) => {
    const getParams = transformCamelToSnake({ sku, warehouseCode });
    const response = await apiClient.getData("/manage/product/product-warehouse/stocks/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};
export default specialShipmentApi;
