const StoreAddressOptions = {
  Dome: {
    receiverName: "Urmart 門市 - 國館巨蛋店",
    receiverPhone: "0277515350",
    city: "台北市",
    region: { key: 106, value: "大安區", label: "大安區" },
    regionName: "大安區",
    address: "忠孝東路四段310號",
  },
};

export default StoreAddressOptions;
